<template>
  <div>
    <v-overlay :value="overlay">
      <v-row class="text-center">
        <v-col cols="12">
          <div class="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </v-col>
        <v-col cols="12">
          <span class="text-lds"> Espere un momento por favor</span>
        </v-col>
      </v-row>
    </v-overlay>

    <v-navigation-drawer class="lisNav" v-model="drawer" app v-show="drawer">
      <v-divider></v-divider>
      <v-list>
        <!----------------------------- Modulos es admin ---------------->
        <span v-if="validate.credentials.isAdmin()">
          <div class="pa-4 text-center">
            <a href="/">
              <v-avatar class="mb-2" size="64">
                <v-img
                  contain
                  height="30"
                  src="https://midgrab.mx/services_test/public/midgrab/M.png"
                ></v-img>
              </v-avatar>
            </a>
            <div class="textNav">ADMINISTRACIÓN</div>
          </div>

          <v-list-item
            v-for="[icon, text, view] in linksHome"
            :key="icon"
            link
            :class="view == urlActiva ? 'listActive' : ''"
            @click="handle_navigate(view)"
          >
            <v-list-item-icon>
              <v-icon :class="view == urlActiva ? 'listActive' : ''">{{
                icon
              }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title
                :class="view == urlActiva ? 'listActive' : ''"
                >{{ text }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-group :value="false">
            <template v-slot:activator>
              <v-icon>mdi-cogs</v-icon>
              <v-list-item>Roles</v-list-item>
            </template>
            <v-list-item
              v-for="[icon, text, view] in linksRoles"
              :key="icon"
              link
              :class="view == urlActiva ? 'listActive' : ''"
              @click="handle_navigate(view)"
            >
              <v-list-item-icon>
                <v-icon :class="view == urlActiva ? 'listActive' : ''">{{
                  icon
                }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title
                  :class="view == urlActiva ? 'listActive' : ''"
                  >{{ text }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-group :value="false">
            <template v-slot:activator>
              <v-icon>mdi-chart-line-variant</v-icon>
              <v-list-item color="#eee">Graficas</v-list-item>
            </template>
            <v-list-item
              v-for="[icon, text, view] in linksGraficas"
              :key="icon"
              link
              :class="view == urlActiva ? 'listActive' : ''"
              @click="handle_navigate(view)"
            >
              <v-list-item-icon>
                <v-icon :class="view == urlActiva ? 'listActive' : ''">{{
                  icon
                }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title
                  :class="view == urlActiva ? 'listActive' : ''"
                  >{{ text }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-group :value="false">
            <template v-slot:activator>
              <v-icon>mdi-credit-card</v-icon>
              <v-list-item>Credito</v-list-item>
            </template>

            <v-list-item
              v-for="[icon, text, view] in linksCredito"
              :key="icon"
              link
              :class="view == urlActiva ? 'listActive' : ''"
              @click="handle_navigate(view)"
            >
              <v-list-item-icon>
                <v-icon :class="view == urlActiva ? 'listActive' : ''">{{
                  icon
                }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title
                  :class="view == urlActiva ? 'listActive' : ''"
                  >{{ text }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <!-- QHSE -->
          <span v-if="moduleQHSE.length > 0">
            <v-list-group :value="false" link color="#b7b2b2">
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon>mdi mdi-hard-hat</v-icon>
                </v-list-item-icon>
                <v-list-item style="padding: 0px !important"
                  >QHSE</v-list-item
                >
              </template>
              <v-list-item
                v-for="n in moduleQHSE"
                :key="n.id"
                :class="n.module_view == urlActiva ? 'listActive' : ''"
                @click="goToModule(n.module_view)"
              >
                <v-list-item-icon>
                  <v-icon>{{ n.thumbnail }}</v-icon>
                </v-list-item-icon>

                <v-list-item-title>{{ n.name }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </span>
          <span v-else></span>
          <!-- QHSE -->

          <span v-if="moduleCatalogo.length > 0">
            <v-list-group :value="false" link color="#b7b2b2">
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon>mdi-database</v-icon>
                </v-list-item-icon>
                <v-list-item style="padding: 0px !important"
                  >Catalogos</v-list-item
                >
              </template>
              <v-list-item
                v-for="n in moduleCatalogo"
                :key="n.id"
                :class="n.module_view == urlActiva ? 'listActive' : ''"
                @click="goToModule(n.module_view)"
              >
                <v-list-item-icon>
                  <v-icon>{{ n.thumbnail }}</v-icon>
                </v-list-item-icon>

                <v-list-item-title>{{ n.name }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </span>
          <span v-else></span>
          <span v-if="moduleContabilidad.length > 0">
            <v-list-group :value="false" link color="#b7b2b2">
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon>mdi-bank</v-icon>
                </v-list-item-icon>
                <v-list-item style="padding: 0px !important"
                  >Contabilidad</v-list-item
                >
              </template>
              <v-list-item
                v-for="n in moduleContabilidad"
                :key="n.id"
                :class="n.module_view == urlActiva ? 'listActive' : ''"
                @click="goToModule(n.module_view)"
              >
                <v-list-item-icon>
                  <v-icon>{{ n.thumbnail }}</v-icon>
                </v-list-item-icon>

                <v-list-item-title>{{ n.name }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </span>
          <span v-else></span>

          <span v-if="modulesCompras.length > 0">
            <v-list-group :value="false" link color="#b7b2b2">
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon>mdi-cart-variant</v-icon>
                </v-list-item-icon>
                <v-list-item style="padding: 0px !important"
                  >Compras</v-list-item
                >
              </template>
              <v-list-item
                v-for="n in modulesCompras"
                :key="n.id"
                :class="n.module_view == urlActiva ? 'listActive' : ''"
                @click="goToModule(n.module_view)"
              >
                <v-list-item-icon>
                  <v-icon>{{ n.thumbnail }}</v-icon>
                </v-list-item-icon>

                <v-list-item-title>{{ n.name }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </span>
          <span v-else></span>

          <span v-if="modulesAlmacen.length > 0">
            <v-list-group :value="false" link color="#b7b2b2">
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon>mdi-store</v-icon>
                </v-list-item-icon>
                <v-list-item style="padding: 0px !important"
                  >Almacén</v-list-item
                >
              </template>
              <v-list-item
                v-for="n in modulesAlmacen"
                :key="n.id"
                :class="n.module_view == urlActiva ? 'listActive' : ''"
                @click="goToModule(n.module_view)"
              >
                <v-list-item-icon>
                  <v-icon>{{ n.thumbnail }}</v-icon>
                </v-list-item-icon>

                <v-list-item-title>{{ n.name }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </span>
          <span v-else></span>

          <span v-for="n in modules" :key="n.id">
            <v-list-item
              link
              @click="goToModule(n.module_view)"
              :class="n.module_view == urlActiva ? 'listActive' : ''"
            >
              <v-list-item-icon>
                <v-icon>{{ n.thumbnail }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ n.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </span>
        </span>
        <!----------------------------- Modulos no es admin ------------->
        <span v-else>
          <div class="pa-4 text-center">
            <a href="/">
              <v-avatar class="mb-2" size="64">
                <v-img
                  contain
                  height="30"
                  src="https://midgrab.mx/services_test/public/midgrab/M.png"
                ></v-img>
              </v-avatar>
            </a>
            <div class="textNav">MIDAS</div>
          </div>
          <v-list-item
            v-for="[icon, text, view] in linksHome"
            :key="icon"
            link
            :class="view == urlActiva ? 'listActive' : ''"
            @click="handle_navigate(view)"
          >
            <v-list-item-icon>
              <v-icon :class="view == urlActiva ? 'listActive' : ''">{{
                icon
              }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title
                :class="view == urlActiva ? 'listActive' : ''"
                >{{ text }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <span v-if="moduleCatalogo.length > 0">
            <v-list-group :value="false" link color="#b7b2b2">
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon>mdi-database</v-icon>
                </v-list-item-icon>
                <v-list-item style="padding: 0px !important"
                  >Catalogos</v-list-item
                >
              </template>
              <v-list-item
                v-for="n in moduleCatalogo"
                :key="n.id"
                :class="n.module_view == urlActiva ? 'listActive' : ''"
                @click="goToModule(n.module_view)"
              >
                <v-list-item-icon>
                  <v-icon>{{ n.thumbnail }}</v-icon>
                </v-list-item-icon>

                <v-list-item-title>{{ n.name }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </span>
          <span v-else></span>
          <!-- QHSE -->
          <span v-if="moduleQHSE.length > 0">
            <v-list-group :value="false" link color="#b7b2b2">
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon>mdi mdi-hard-hat</v-icon>
                </v-list-item-icon>
                <v-list-item style="padding: 0px !important"
                  >QHSE</v-list-item
                >
              </template>
              <v-list-item
                v-for="n in moduleQHSE"
                :key="n.id"
                :class="n.module_view == urlActiva ? 'listActive' : ''"
                @click="goToModule(n.module_view)"
              >
                <v-list-item-icon>
                  <v-icon>{{ n.thumbnail }}</v-icon>
                </v-list-item-icon>

                <v-list-item-title>{{ n.name }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </span>
          <span v-else></span>
          <!-- QHSE -->
          <span v-if="moduleContabilidad.length > 0">
            <v-list-group :value="false" link color="#b7b2b2">
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon>mdi-bank</v-icon>
                </v-list-item-icon>
                <v-list-item style="padding: 0px !important"
                  >Contabilidad</v-list-item
                >
              </template>
              <v-list-item
                v-for="n in moduleContabilidad"
                :key="n.id"
                :class="n.module_view == urlActiva ? 'listActive' : ''"
                @click="goToModule(n.module_view)"
              >
                <v-list-item-icon>
                  <v-icon>{{ n.thumbnail }}</v-icon>
                </v-list-item-icon>

                <v-list-item-title>{{ n.name }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </span>
          <span v-else></span>

          <span v-if="modulesCompras.length > 0">
            <v-list-group :value="false" link color="#b7b2b2">
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon>mdi-cart-variant</v-icon>
                </v-list-item-icon>
                <v-list-item style="padding: 0px !important"
                  >Compras</v-list-item
                >
              </template>
              <v-list-item
                v-for="n in modulesCompras"
                :key="n.id"
                :class="n.module_view == urlActiva ? 'listActive' : '#fff'"
                @click="goToModule(n.module_view)"
              >
                <v-list-item-icon>
                  <v-icon>{{ n.thumbnail }}</v-icon>
                </v-list-item-icon>

                <v-list-item-title>{{ n.name }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </span>
          <span v-else></span>

          <span v-if="modulesAlmacen.length > 0" style="background-color: red">
            <v-list-group :value="false" link color="#b7b2b2">
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon>mdi-store</v-icon>
                </v-list-item-icon>
                <v-list-item style="padding: 0px !important"
                  >Almacén</v-list-item
                >
              </template>
              <v-list-item
                v-for="n in modulesAlmacen"
                :key="n.id"
                :class="n.module_view == urlActiva ? 'listActive' : '#fff'"
                @click="goToModule(n.module_view)"
              >
                <v-list-item-icon>
                  <v-icon>{{ n.thumbnail }}</v-icon>
                </v-list-item-icon>

                <v-list-item-title>{{ n.name }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </span>
          <span v-else></span>

          <span v-for="n in modules" :key="n.id">
            <v-list-item
              link
              @click="goToModule(n.module_view)"
              :class="n.module_view == urlActiva ? 'listActive' : ''"
            >
              <v-list-item-icon>
                <v-icon>{{ n.thumbnail }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ n.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </span>
        </span>
      </v-list>
    </v-navigation-drawer>
    <!----------------------------- Barra notificaciones ------------->
    <v-row>
      <v-col cols="12">
        <v-app-bar app class="toolbar-main" v-if="user">
          <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
          <v-toolbar-title
            ><div>
              <span class="textNav nameS">
                Modulo Integral de Desarrollo, Administracion y Servicios
              </span>
            </div>
            <div class="nameSystem" style="display: none">
              <span class="textNav"> MIDAS </span>
            </div>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <div
            class="d-flex flex-wrap justify-end align-self-center align-center ml-5"
          >
            <v-menu class="align-self-center" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="btnNotificacion"
                  outlined
                  fab
                  dark
                  x-small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-badge
                    v-if="messages && messages.length > 0"
                    :content="messages.length"
                    color="primary accent-4"
                    offset-x="10"
                    offset-y="10"
                  >
                    <v-img
                      src="@/assets/images/icons/svg/notififacion-off.svg"
                      height="20"
                      contain
                    ></v-img>
                  </v-badge>
                  <v-img
                    v-else
                    src="@/assets/images/icons/svg/notififacion-off.svg"
                    height="20"
                    contain
                  ></v-img>
                </v-btn>
              </template>
              <v-list dense width="200" class="lisNav">
                <v-list-item link to="/notificaciones">
                  <span class="menu-item-txt">Notificaciones</span>
                </v-list-item>
              </v-list>
              <!-- <v-list>
                <v-list-item
                  v-for="(m, i) in messages"
                  :key="i"
                  to="/notificaciones"
                >
                  <span class="menu-item-txt">{{ m.title }}</span>
                </v-list-item>
              </v-list> -->
            </v-menu>
            <v-menu class="align-self-center" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-badge
                  bordered
                  bottom
                  color="success accent-4"
                  dot
                  offset-x="10"
                  offset-y="10"
                >
                  <v-btn class="mx-4" fab small v-bind="attrs" v-on="on">
                    <v-img
                      src="@/assets/images/icons/svg/usuarioPrincipal.svg"
                      height="24"
                      contain
                    ></v-img>
                  </v-btn>
                </v-badge>
              </template>
              <v-list dense width="200" class="lisNav">
                <v-list-item class="nameSub" style="display: none">
                  <span class="menu-item-txt"> {{ user.name }}</span>
                </v-list-item>
                <div v-for="(m, i) in userMenu" :key="i">
                  <v-list-item link :to="m.page" v-if="m.menu">
                    <span class="menu-item-txt">{{ m.text }}</span>
                  </v-list-item>
                  <v-divider v-else></v-divider>
                </div>
              </v-list>
            </v-menu>

            <div class="d-flex flex-wrap flex-column mx-5 nameMenu">
              <span class="textUser">{{ user.name }}</span>
              <span v-if="user.cia" class="subTextUser">{{
                user.cia.name
              }}</span>
              <span v-else class="subTextUser">{{ user.identity.label }}</span>
            </div>
          </div>
        </v-app-bar>
      </v-col>
    </v-row>
    <v-main>
      <Breadcrumbs :parent="parentView" class="breadcrumbs" />
      <v-container class="px-5" fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import validate from "@/tools/validate.access";
import Breadcrumbs from "../features/Breadcrumbs.vue";

export default {
  components: { Breadcrumbs },
  data: () => ({
    validate: validate,
    drawer: true,
    modules: [],
    moduleCatalogo: [],
    moduleQHSE: [],
    moduleContabilidad: [],
    modulesCompras: [],
    modulesAlmacen: [],
    catalogoActive: false,
    parentView: { name: "Administracion MIDAS", view: "/" },
    // drawer: null,
    urlActiva: null,
    linksRoles: [
      ["mdi-square-medium", "Usuarios", "/usuarios"],
      // ["mdi-apps", "Modulos", "/administracion/modulos"],
    ],
    linksHome: [["mdi-home", "Home", "/"]],
    linksGraficas: [
      ["mdi-square-medium", "Metricas", "/administracion/reportes"],
    ],
    linksCredito: [
      ["mdi-square-medium", "Creditos", "/creditos"],
      ["mdi-square-medium 1", "Solicitud Credito", "/solicitud_creditos"],
    ],
    linksClientes: [
      ["mdi-text-box", "Reportes", "/certificados"],
      ["mdi-wrench", "Manufactura", "/manufactura"],
    ],
    userMenu: [
      // { text: "Notificaciones", page: "/notificaciones", menu: true },
      { menu: false },
      { text: "Cerrar sesión", page: "/logout", menu: true },
    ],
  }),
  methods: {
    get_messages() {
      this.$store.dispatch("messaging/get_messages");
    },
    handle_navigate(url) {
      this.urlActiva = url;
      this.$router.push(url).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
    // Admin nueva
    goToModule(module) {
      this.urlActiva = module;
      this.$router.push(module).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
    set_modules() {
      let arrayModulos = this.$getModules();
      let arrayCatalogo = [];
      let arrayQHSE = [];
      let arrayContabilidad = [];
      let arrayModule = [];
      let arrayCompras = [];
      let arrayAlmacen = [];

      arrayModulos.map((element) => {
        if (element.clasification == "ADMIN") {
          arrayCatalogo.push(element);
        } else if (element.clasification == "CONTABILIDAD") {
          arrayContabilidad.push(element);
        } else if (element.clasification == "COMPRAS") {
          arrayCompras.push(element);
        } else if (element.clasification == "ALMACEN") {
          arrayAlmacen.push(element);
        } else if (element.clasification == "QHSE") {
          arrayQHSE.push(element);
        } else {
          arrayModule.push(element);
        }
      });

      this.moduleCatalogo = arrayCatalogo;
      this.moduleContabilidad = arrayContabilidad;
      this.modules = arrayModule;
      this.modulesCompras = arrayCompras;
      this.modulesAlmacen = arrayAlmacen;
      this.moduleQHSE = arrayQHSE;
    },
  },
  created() {
    this.set_modules();
    this.get_messages();
  },
  mounted() {
    this.urlActiva = this.$route.path;
    this.$store.dispatch("messaging/subscribe_to_group").then(()=>{},()=>{});;
  },
  computed: {
    user() {
      return this.$store.getters["authenticate/getDataUser"];
    },
    messages() {
      return this.$store.getters["messaging/getMessagesTB"].unreaded;
    },
    overlay() {
      return this.$store.getters["configurations/getLoading"];
    },
  },
};
</script>

<style scoped>
.theme--light.v-list-item:not(.v-list-item--active):not(
    .v-list-item--disabled
  ) {
  color: #eee;
}
/* icon lis nav */
.v-icon.v-icon {
  color: #b7b2b2;
}
.mdi-chevron-down::before {
  color: #fff;
}
@media only screen and (max-width: 816px) {
  .nameSub {
    display: block !important;
  }
  .nameMenu {
    display: none !important;
  }
}
@media only screen and (max-width: 765px) {
  .nameSystem {
    display: block !important;
  }
  .nameS {
    display: none !important;
  }
  .nameSub {
    display: block !important;
  }
  .nameMenu {
    display: none !important;
  }
}
</style>
